import {
  ListCheck,
  CancelCheck,
  BlueInfo,
  ChinaHowItWorks1,
  ChinaHowItWorks2,
  ChinaHowItWorks3,
  ChinaHowItWorks4,
  ChinaCustomerSupport,
  ChinaRates,
  ChinaInsurance,
  ChineseImg
} from '../constants/asset-constants';
import FaqBlock from '../components/faq-block';
import GetMobileApp from '../components/new-home-page/get-mobile-app';
import NewFooter from '../components/new-footer';
import { chinaImportQuestions } from '../constants/faqs';
import { ContactForm } from '../components/contact-form';
import { TermsAndConditions } from '../constants/terms-&-condition';
import { Helmet } from 'react-helmet';

const pricing = [
  {
    price: 10,
    title: 'Fashion items, Hair and Jewelry',
    tags: [
      'Inclusive of import duties',
      'Delivery in 10 - 15 days',
      'No pick-ups',
      'No doorstep delivery'
    ]
  },
  {
    price: 18,
    title: 'Liquid Cosmetics and Skin Care Products',
    tags: [
      'Inclusive of import duties',
      'Delivery in 12 - 25 days',
      'No pick-ups',
      'No doorstep delivery'
    ]
  },
  {
    price: 17,
    title: 'Phones and Laptops devices',
    tags: [
      'Inclusive of import duties',
      'Delivery in 12 - 25 days',
      'No pick-ups',
      'No doorstep delivery'
    ]
  },
  {
    price: 17,
    title: 'Medical and Pharmaceutical Items',
    tags: [
      'Inclusive of import duties',
      'Delivery in 12 - 25 days',
      'No pick-ups',
      'No doorstep delivery'
    ]
  }
];

const notices: Record<number, string> = {
  2: 'Ship to our warehouse in china',
  3: 'Comes with an extra fee'
};

const steps = [
  {
    icon: ChinaHowItWorks1,
    title:
      'On your Topship dashboard, go to ‘Book Shipments -> Book an Import -> China Imports’. Then, fill out the shipment booking form'
  },
  {
    icon: ChinaHowItWorks2,
    title: 'Complete payment & get our partner address in China'
  },
  {
    icon: ChinaHowItWorks3,
    title:
      'Inform your supplier to ship your goods to the China address provided'
  },
  {
    icon: ChinaHowItWorks4,
    title:
      'You will be notified when your shipment arrives in Lagos, Nigeria. Doorstep delivery comes at an additional charge.'
  }
];

const faqFeatures = [
  {
    icon: ChinaCustomerSupport,
    text: 'Reliable and friendly customer  support representatives'
  },
  {
    icon: ChinaRates,
    text: 'Affordable rates'
  },
  {
    icon: ChinaInsurance,
    text: 'Flexible insurance options'
  }
];

const ChinaImports = () => {
  return (
    <>
      <Helmet>
        <title>Ship from China to Nigeria with Topship</title>
        <meta
          name='description'
          content='Shop and ship directly from your manufacturers in China with Topship and enjoy doorstep delivery in Nigeria. Elevate your logistics operations with our comprehensive suite of services and expert support.'
        />
        <meta
          name='keywords'
          content='Logistics business, third-party logistics, Topship, logistics service, logistics company, freight forwarding, 3PL, logistics provider, logistics shipping company, supply chain management'
        />
      </Helmet>
      <div className='china-imports'>
        {/* web */}
        <div className='china-imports__intro'>
          <div className='china-imports__intro__wrap'>
            <div className='content-wrap'>
              <h1 className='heading'>
                Your <span>Gateway</span> to <br />
                China’s Marketplace
              </h1>
              <p className='paragraph mt-3'>
                Shop and ship directly from suppliers and <br /> manufacturers
                in China.
              </p>
              <div className='cta-group mt-3'>
                <a href='#contact-form' className='contact-support'>
                  Contact Us
                </a>
                <button
                  onClick={() => window.open('https://ship.topship.africa')}
                  className='start-shipping'
                >
                  Start Shipping
                </button>
              </div>
            </div>
            <img src={ChineseImg} alt='chinese' />
          </div>
        </div>

        <div className='china-imports__pricing'>
          <div className='china-imports__pricing__wrap'>
            <div className='china-imports__pricing__header'>
              <h1>Pricing Options</h1>
            </div>
            <div className='china-imports__pricing__items'>
              <div className='china-imports__pricing__items__wrap'>
                {pricing.map((price, index) => (
                  <div key={index} className='china-imports__pricing__item'>
                    <div className='china-imports__pricing__item__wrap'>
                      <h2 className='china-imports__pricing__item__header'>
                        {price.title}
                        {[0, 2, 3].includes(index) &&
                          window.innerWidth >= 800 && <p>&nbsp;</p>}
                      </h2>
                      <span className='china-imports__pricing__item__text'>
                        Starts at
                      </span>
                      <p className='china-imports__pricing__item__content'>
                        <span className='china-imports__pricing__item__price'>
                          USD {price.price}{' '}
                        </span>
                        <span className='china-imports__pricing__item__priceText'>
                          per kg
                        </span>
                      </p>
                      <div className='china-imports__pricing__item__features'>
                        {price.tags.map((feature, featureIndex) => (
                          <div
                            key={featureIndex}
                            className='china-imports__pricing__item__feature'
                          >
                            <img
                              src={featureIndex > 1 ? CancelCheck : ListCheck}
                              alt={feature}
                            />
                            <div className='china-imports__pricing__item__feature__main'>
                              <span>{feature}</span>
                              {featureIndex > 1 && (
                                <span className='china-imports__pricing__item__feature__notice'>
                                  {notices[featureIndex]}
                                </span>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                      <button
                        onClick={() =>
                          window.open('https://ship.topship.africa')
                        }
                        className='china-imports__pricing__item__action'
                      >
                        Import Now
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className='china-imports__pricing__alert'>
              <div className='china-imports__pricing__alert__wrap'>
                <div className='china-imports__pricing__alert__icon'>
                  <img src={BlueInfo} alt='info' />
                </div>
                <div className='china-imports__pricing__alert__content'>
                  <span>
                    No pick-ups. You need to ship directly to the warehouse.
                    Also, an additional charge will be determined once it lands
                    in Lagos based on the final destination address.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='china-imports__how-it-works'>
          <div className='china-imports__how-it-works__wrap'>
            <div className='china-imports__how-it-works__header'>
              <h1>How it works</h1>
            </div>
            <div className='china-imports__how-it-works__items'>
              {steps.map((step, index) => (
                <div className='china-imports__how-it-works__item'>
                  <div className='china-imports__how-it-works__item__wrap'>
                    <div className='china-imports__how-it-works__item__icon'>
                      <img src={step.icon} alt={step.title} />
                    </div>
                    <div className='china-imports__how-it-works__item__header'>
                      <h2>Step 0{index + 1}</h2>
                    </div>
                    <div
                      style={{
                        textAlign: 'left'
                      }}
                      className='china-imports__how-it-works__item__content'
                    >
                      <span>{step.title}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <TermsAndConditions isChina={true} />

        <div className='china-imports__faq'>
          <div className='china-imports__faq__wrap'>
            <div className='china-imports__faq__header'>
              <h1>
                Enjoy all of these and more <br />
                on each shipping option
              </h1>
            </div>
            <div className='china-imports__faq__features'>
              {faqFeatures.map((feature, index) => (
                <div key={index} className='china-imports__faq__feature'>
                  <div className='china-imports__faq__feature__wrap'>
                    <div className='china-imports__faq__feature__icon'>
                      <img src={feature.icon} alt={feature.text} />
                    </div>
                    <div className='china-imports__faq__feature__content'>
                      <span>{feature.text}</span>
                      {index > 0 && <p>&nbsp;</p>}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <ContactForm serviceTitle={'China Import'} />
        <FaqBlock questions={chinaImportQuestions} />

        <GetMobileApp />
        <section className='new-home-page__footer'>
          <NewFooter />
        </section>
      </div>
    </>
  );
};

export default ChinaImports;
